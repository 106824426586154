<template>
  <div>
    <h2 class="th-title">Views Terbanyak</h2>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <a-input
          style="width: 200px"
          v-model:value="search"
          @change="handleSearchBanner"
          placeholder="Cari berdasarkan akun"
        >
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
        <!-- <a-button @click.prevent="showModalAdd">
          <template #icon><plus-outlined /></template>
          Add Report
        </a-button> -->
        <a-modal v-model:visible="modalAddVisible" title="Tambahkan Banner" @ok="handleOk">
          <a-form layout="vertical" :model="formAdd">
            <a-form-item required label="Nama" name="name" v-bind="validateInfos.name">
              <a-input placeholder="Masukkan nama banner" v-model:value="formAdd.name" />
            </a-form-item>
            <a-form-item label="Deskripsi">
              <a-textarea :rows="6" placeholder="Masukkan deskripsi (opsional)" v-model:value="formAdd.description" show-count :maxlength="100" />
            </a-form-item>
            <a-form-item required label="URL">
              <a-input placeholder="Masukkan url banner" v-model:value="formAdd.url" />
            </a-form-item>
            <a-form-item required label="Status">
              <a-switch v-model:checked="formAdd.isActive" @click="(e) => onStatusClick(e, 'add')"/>
            </a-form-item>
            <a-upload
              list-type="picture-card"
              :multiple="false"
              :before-upload="beforeUpload"
              v-model:value="imageBanner"
              @preview="handlePreviewImage"
              @change="handleChangeImage"
            >
              <div v-if="imageBanner === null">
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisibleImage" :footer="null" @cancel="handleCancelImage">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form>
          <template #footer>
            <a-button @click.prevent="confirmAdd" key="submit" type="primary" html-type="submit">Tambahkan</a-button>
          </template>
        </a-modal>
      </div>
      <div>
        <a-table :class="$style.table" :columns="columns" :data-source="data" bordered>
          <template #account="{ text, record }">
            <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.key } }">{{text}}</router-link>
          </template>
          <template #viewer="{ text }">
            {{ text.length }}
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
// import axios from 'axios'
import apiClient from '@/services/axios'

const columns = [
  {
    title: 'Posisi',
    align: 'center',
    width: 100,
    dataIndex: 'posisi',
    key: 'posisi',
    slots: { customRender: 'posisi' },
  },
  {
    title: 'Akun',
    align: 'center',
    width: 100,
    dataIndex: 'account',
    key: 'account',
    slots: { customRender: 'account' },
  },
  {
    title: 'Total Views',
    align: 'center',
    width: 200,
    dataIndex: 'viewer',
    key: 'viewer',
    slots: { customRender: 'viewer' },
  },
];

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  setup() {
    const store = useStore()
    let data = ref([])
    let imageBanner = ref(null)
    let search = ref(null)
    let loadingTable = ref(false)
    let modalAddVisible = ref(false)
    let modalWarnVisible = ref(false)
    let newFileStatus = ref(true)
    let previewVisibleImage = ref(false)
    let previewImage = ref(null)
    let backendUrl = ref(process.env.VUE_APP_BACKEND_URL)
    let backendUrlSlice = ref(null)
    let formAdd = reactive({
      name: null,
      description: null,
      url: null,
      isActive: false,
    })
    let formWarn = reactive({
      message: null,
    })
    const loadingAdd = ref(false)
    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Nama harus diisi!',
          trigger: 'change',
        },
      ],
    })
    let formEdit = ref({
      name: null,
      description: null,
      image: null,
      url: null,
      isActive: false,
    })
    let idEdit = ref(null)

    onMounted(async () => {
      fetchViews()
      sliceUrl()
    })

    const fetchViews = async (search = '') => {
      // loadingTable.value = true
      // const rawData = await store.dispatch('banner/FETCH_BANNER', search)
      // // console.log('fetchBanner rawData => ', rawData)
      // loadingTable.value = false
      // data.value = rawData.map(row => {
      //   return {
      //     key: row._id,
      //     name: row.name,
      //     description: row.description,
      //     image: row.image,
      //     url: row.url,
      //     isActive: row.isActive,
      //   }
      // })
      const views = store.state.akun.views
      for (let i = 0; i < views.length; i++) views[i].posisi = i + 1
      data.value = store.state.akun.views
    }

    const showModalAdd = () => {
      modalAddVisible.value = true
    }

    const showModalWarn = () => {
      modalWarnVisible.value = true
    }

    const sliceUrl = () => {
      const url = backendUrl.value
      backendUrlSlice.value = url.slice(0, -1)
      console.log(backendUrlSlice.value)
    }

    const onStatusClick = (value, type) => {
      console.log('onStatusClick', value, type)
      if (type === 'add') {
        formAdd.isActive = value
      } else {
        formEdit.value.isActive = value
      }
    }

    const beforeUpload = (file) => {
      newFileStatus.value = false
      imageBanner.value = file
      // console.log('beforeUpload imageBanner =>', imageBanner)
      return false
    }

    const handleChangeImage = (value) => {
      const fileList = value.fileList
      const banner = imageBanner.value
      const imageType = banner.type
      // console.log('handleChangeImage imageType => ', imageType)
      if (imageType === 'image/jpg' || imageType === 'image/jpeg'|| imageType === 'image/png') {
        if (fileList.length) {
          notification.success({
            message: 'Berhasil Upload Image.',
          })
        } else {
          imageBanner.value = null
          notification.warning({
            message: 'Berhasil Hapus Image!',
            description: 'Mohon upload kembali image terbaru untuk banner!',
          })
        }
      } else {
        imageBanner.value = null
        newFileStatus.value = true
        notification.error({
          message: 'Gagal Upload Image!',
          description: `Tipe file ini tidak diizinkan ${imageType}! Tipe file hanya boleh: jpg / jpeg / png.`,
        })
      }
    }

    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    }

    const handlePreviewImage = async (file) => {
      try {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj)
        }
        previewImage.value = file.url || file.preview
        previewVisibleImage.value = true
      } catch(err) {
        console.log('handlePreviewImage error => ', err)
      }
    }

    const handleCancelImage = () => {
      previewVisibleImage.value = false
    }

    const handleOk = () => {
      modalAddVisible.value = false
    }

        const closeEdit = () => idEdit.value = null

    const confirmEdit = async (record) => {
      try {
        // const payload = {
        //   id: record.key,
        //   data: {
        //     name: formEdit.value.name,
        //     description: formEdit.value.description,
        //   },
        // }
        await validateEdit()
        Modal.confirm({
          title: `Apa anda yakin ingin mengubah data pada banner '${record.name}'?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            const id =  record.key
            const formData = new FormData()
            const banner = imageBanner.value
            const name = formEdit.value.name
            const description = formEdit.value.description
            const url = formEdit.value.url
            const isActive = formEdit.value.isActive
            if (banner !== null) {
              formData.append('banner', banner)
            }
            formData.append('name', name)
            formData.append('description', description)
            formData.append('url', url)
            formData.append('isActive', isActive)
            try {
              await apiClient.put(`${backendUrl.value}api/banner/update/${id}`, formData)
              imageBanner.value = null
              fetchBanner()
              notification.success({
                message: `Berhasil Mengubah Data Pada Banner '${record.name}'`,
              })
              idEdit.value = null
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch (err) {
        console.log(err)
      }
    }

    const { resetFields, validate, validateInfos } = useForm(formAdd,  rulesRef);
    const { validate:validateEdit, validateInfos:validateInfosEdit } = useForm(formEdit, rulesRef);
    const { validate:validateWarn, validateInfos:validateInfosWarn } = useForm(formWarn, rulesRef);

    const confirmWarn = async () => {
      try {
        await validateWarn()
        // console.log(console.log(toRaw(formAddRef)))
        Modal.confirm({
          title: 'Apa anda yakin ingin memberi peringatan?',
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            notification.success({
              message: `Peringatan berhasil diberikan.`,
            })
            modalWarnVisible.value = false
          },
          onCancel() {},
        })
      } catch (err) {
        console.log(err)
      }
    }

    const confirmAdd = async () => {
      try {
        // console.log('confirmAdd ', formAdd, imageBanner)
        loadingAdd.value = true
        await validate()
        // console.log(console.log(toRaw(formAddRef)))
        Modal.confirm({
          title: 'Apa anda yakin ingin menambahkan banner?',
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            const formData = new FormData()
            const banner = imageBanner.value
            const name = formAdd.name
            const description = formAdd.description
            const url = formAdd.url
            const isActive = formAdd.isActive
            formData.append('banner', banner)
            formData.append('name', name)
            formData.append('description', description)
            formData.append('url', url)
            formData.append('isActive', isActive)
            try {
              await apiClient.post(`${backendUrl.value}api/banner/create`, formData)
              imageBanner.value = null
              modalAddVisible.value = false
              loadingAdd.value = false
              fetchBanner()
              notification.success({
                message: `Berhasil Menambahkan '${formAdd.name}' Dari Daftar Banner`,
              })
              resetFields()
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch(err) {
        loadingAdd.value = false
      }
    }

    const bannerAction = async (record, action) => {
      const data = {
        id: record.key,
        data: {
          name: record.name,
          description: record.description,
        },
      }
      if (action === 'DELETE') {
        Modal.confirm({
          title: `Apa anda yakin ingin menghapus banner '${record.name}' dari daftar?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const res = await store.dispatch('banner/DELETE_BANNER', { ...data })
              fetchBanner()
              notification.success({
                message: `Berhasil Menghapus '${record.name}' Dari Daftar Banner`,
              })
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } else if (action === 'UPDATE') {
        idEdit.value  = record.key
        formEdit.value = {
          name: record.name,
          description: record.description || null,
          image: record.image || null,
          url: record.url || null,
          isActive: record.isActive || null,
        }
        // const data = await store.dispatch(action + '_BANNER', { id, data })
      }
    }
    
    const handleSearchBanner = () => {
      fetchBanner(search.value)
    }

    return {
      data,
      columns,
      loadingTable,
      search,
      modalAddVisible,
      showModalAdd,
      showModalWarn,
      modalWarnVisible,
      onStatusClick,
      handleOk,
      formAdd,
      formWarn,
      rulesRef,
      loadingAdd,
      confirmAdd,
      confirmWarn,
      validate,
      validateInfos,
      handleChangeImage,
      newFileStatus,
      beforeUpload,
      getBase64,
      previewVisibleImage,
      previewImage,
      handlePreviewImage,
      handleCancelImage,
      imageBanner,
      validateEdit,
      validateInfosEdit,
      validateInfosWarn,
      bannerAction,
      idEdit,
      formEdit,
      closeEdit,
      confirmEdit,
      backendUrl,
      backendUrlSlice,
      handleSearchBanner,
    }
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>